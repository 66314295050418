<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="getList" v-model="search.title" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList(data.size)">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加人员
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->

                    <el-button class="block" @click="AlonePower">
                        <i class="el-icon-monitor"></i>
                        独立权限
                    </el-button>
                    <el-button class="block" @click="resetPWD">
                        <i class="el-icon-lock"></i>
                        重置密码
                    </el-button>

                </el-form-item>
            </el-form>
        </div>

        <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border>


            <el-table-column show-overflow-tooltip prop="ID" label="ID" align="left" width="80" />

            <el-table-column show-overflow-tooltip prop="img" label="头像" align="left" width="61">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.img" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Company" label="单位名称" align="left" />
            <el-table-column show-overflow-tooltip prop="name" label="真实姓名" align="left" width="70" />
            <el-table-column show-overflow-tooltip prop="sex" label="性别" align="center" width="50" />
            <el-table-column show-overflow-tooltip prop="Department" label="科室" align="left" />
            <el-table-column show-overflow-tooltip prop="Station" label="岗位" align="center" width="60" />
            <el-table-column show-overflow-tooltip prop="phone" label="手机号码" align="center" width="100" />

            <el-table-column show-overflow-tooltip prop="isAdmin" label="管理员" align="center" width="60">
                <template slot-scope="scope">
                    <el-button type="primary" round v-if="scope.row.isAdmin" size="mini"> 是 </el-button>
                    <el-button type="danger" round v-if="!scope.row.isAdmin" size="mini"> 否 </el-button>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="times" label="登录次数" align="center" width="70" />

            <el-table-column show-overflow-tooltip prop="state" label="状态" align="center" width="90">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.state" active-color="#5BD995" active-text="正常"
                        inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                    </el-switch>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip label="操作" width="400" align="center">
                <template slot-scope="scope">
                    <div class="operate">

                        <el-button type="primary" @click="view(scope.row)">
                            查看
                        </el-button>
                        <el-button type="danger" @click="edit(scope.row)">
                            编辑
                        </el-button>
                        <el-button type="success" @click="del(scope.row)">
                            删除
                        </el-button>

                        <el-button type="success" style="background-color: #00CFE8;border-color:transparent"
                            @click="AlonePower">
                            独立权限
                        </el-button>
                        <el-button type="warning" @click="resetPWD">
                            重置密码
                        </el-button>

                    </div>

                </template>
            </el-table-column>


        </Fanfu-Table>

        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />


        <AlonePower ref="AlonePowerRef" />

        <StaffListAdd ref="StaffListAdd" />


    </div>
</template>

<script>
    import Search from './components/StaffSearch'
    import AlonePower from './components/AlonePower'

    import StaffListAdd from './components/StaffListAdd.vue'

    import resizeDetector from 'element-resize-detector'


    const list = [{
        ID: '12345678',
        img: 'http://iph.href.lu/100x200?text=%E5%A4%B4%E5%83%8F&fg=f44336&bg=9fc5e8',
        Company: '河东区大王庄街社区卫生服务中心',
        name: '王小明',
        sex: '男',
        Department: '行政四楼',
        Station: '收集人',
        phone: '15712345678',
        isAdmin: true,
        times: "1001",
        state: true
    }]


    export default {
        components: {
            Search,
            AlonePower,
            StaffListAdd
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list,
                    // num: "ID",
                    page: 1,
                    size: 15,
                    total: list.length
                }
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList(size) {


                if (size) {
                    this.data.page = 1
                    this.data.size = size
                }
                this.loading = true
                this.select = []
                this.$nextTick(() => {
                    const start = this.data.size * (this.data.page - 1)
                    const end = start + this.data.size
                    this.data.total = list.length
                    const data = []
                    for (let i = start; i < end; i++) {
                        if (list[i]) {
                            data.push(list[i])
                        }
                    }
                    this.data.list = data
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)



                    setTimeout(() => {
                        console.log("++++++++++++++++++++++++++++++++")

                        this.modifyLayout();
                    }, 1100)


                })



            },
            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },


            resetPWD() {

                const h = this.$createElement;
                this.$msgbox({
                    title: '重置密码',
                    message: h('p', null, [
                        h('span', null, '是否确定要重置密码？'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });

            },



            toSearch() {
                this.$refs.StaffListAdd.open()



            },
            AlonePower() {
                this.$refs.AlonePowerRef.open()
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height;
                    if ($(".level_indexI .FanfuTableBox")[0]) {


                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 33 + height;
                    if ($(".level_indexI .el-table")[0]) {


                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }


                });

            }

        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();
        }





    }
</script>















<style lang="scss" scoped></style>